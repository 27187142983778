import React from 'react';

import styles from '../styles/index_styles';
import Layout from '../components/layout/index';
import Seo from '../components/seo';
import { Link } from 'gatsby';

const NotFoundPage = () => (
	<Layout showHeader={false} showFooter={false}>
		<Seo />
		<main css={styles.thanksContainer}>
			<h1>NOT FOUND</h1>
			<p>This page does not exist... &#39;tis sad.</p>
			<Link to="/">Go Home</Link>
		</main>
	</Layout>
);

export default NotFoundPage;
